.how-it-works-container {
    margin-bottom: 56px;
}

.how-it-works-header {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 0;
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxxxl);
}

.header-text {
    margin: auto;
    text-align: center;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xl);
    width: 620px;
}

.sample-link {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    margin-bottom: 28px;
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-s);
    line-height: var(--line-height-xxxl);
}

a.sample-link:hover {
    color: var(--color-green-dark);
}

.steps-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 900px;
}

.step-wrap {
    width: 284px;
}

.step-img {
    height: 203px;
}

.step-header {
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-s);
    line-height: var(--line-height-l);
}

.step-text {
    margin-top: 8px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-s);
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .how-it-works-container {
        margin-bottom: 0;
    }

    .how-it-works-header {
        text-align: center;
        margin-top: 32px;
        margin-bottom: 0;
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-l);
        line-height: var(--line-height-xxxl);
    }

    .header-text {
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
        width: 380px;
    }

    .steps-container {
        flex-direction: column;
        width: 388px;
    }

    .step-wrap {
        margin: 0 auto 64px;
    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .steps-container {
        width: 100%;
    }

    .header-text {
        width: 320px;
    }

    .steps-container {
        width: 312px;
    }
}

/*Smallest screen breakpoint*/
/* On screens that are 360px wide or less*/
@media (max-width: 360px) {
    .header-text, .steps-container, .step-wrap {
        width: 90%;
    }

    .step-img {
        width: 90%;
        height: inherit;
    }
}
