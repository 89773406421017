.faq-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 670px;
    padding-bottom: 32px;
    padding-top: 32px;
}

.faq-img {
    width: 360px;
    align-self: center;
}

.faq-header {
    align-self: center;
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xxxl);
}

h3 {
    font-family: 'Inter', sans-serif;
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xl);
    margin-bottom: 0;
}

p {
    font-family: 'Inter', sans-serif;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xl);
    margin-bottom: 0;
}

.faq-qa-container {
    margin-left: 64px;
    align-self: start;
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .faq-container {
        width: 380px;
    }
    .faq-img {
        width: 180px;
        justify-content: center;
        align-self: center;
    }
    h3 {
        font-weight: var(--font-weight-darkest);
        font-size: var(--font-size-s);
        line-height: var(--line-height-m);
    }

    p {
        font-family: 'Inter', sans-serif;
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-s);
        line-height: var(--line-height-m);
    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .faq-container {
        width: 100%;
        padding-right: 32px;
        padding-left: 32px;

    }
}
