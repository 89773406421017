.app-bar-container {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 24px 32px 24px 32px;
    background-color: var(--color-neutral-white);
    z-index: 1;
}

.app-bar-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 912px;
    width: 80%;
    margin: auto;
}

.moves-logo {
    width: 200px;
}

.app-bar-moves-link {
    font-weight: var(--font-weight-dark);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-l);
}

a.app-bar-moves-link:hover {
    color: var(--color-green-dark);
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .app-bar-container {
        padding: 24px;
    }

    .moves-logo {
        width: 170px;
    }
    .app-bar-moves-link {
        font-weight: var(--font-weight-dark);
        font-size: var(--font-size-xxs);
        line-height: var(--line-height-m);
    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .app-bar-container {
        padding: 16px 8px 16px 8px;
    }
    .moves-logo {
        width: 120px;
    }
}
