.intro-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 912px;
}

.intro-header {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxxxl);
    line-height: var(--line-height-xxxxxl);
}

.intro-text {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xl);
}

.report-pic{
    width: 400px;
    margin-left: 32px;
    margin-right: 32px;
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .intro-container {
        width: 388px;
        flex-direction: column-reverse;
    }

    .intro-header {
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-xl);
        line-height: var(--line-height-xxl);
        text-align: center;
    }

    .intro-text {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
        text-align: center;
        margin: auto;
    }

    .report-pic {
        width: 250px;
    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .intro-container {
        margin: auto;
        padding-right: 8px;
        padding-left: 8px;
        width: 100%;
    }
}

