.income-report-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 912px;
    margin: 32px auto;
    border: 1px solid var(--color-neutral-light);
    box-shadow: 0 2px 4px rgba(83, 97, 111, 0.2);
    border-radius: 4px;
    background-color: var(--color-neutral-lighter);
}

input::placeholder {
    color: var(--color-neutral-light);
}

.agreement-check-text {
    color: var(--color-custom-57606F);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
    margin: 0 0 0 4px;
}

.get-report-btn {
    background: var(--color-custom-292D34);
    border-radius: 56px;
    color: var(--color-custom-F1F2F6);
    height: 64px;
    width: 620px;
    font-weight: var(--font-weight-dark);
    font-size: var(--font-size-m);
    line-height: var(--line-height-xxxxxl);
}

input[type="submit"].get-report-btn:hover {
    background-color: var(--color-green-lighter);
    color: var(--color-neutral-darkest);
}

input[type="text"]:hover, input[type="text"]:focus {
    border: 1px solid var(--color-green-dark);
    outline:none;
}

.agreement-check-btn {
    background: var(--color-neutral-white);
    border: 1px solid var(--color-neutral-light);
    border-radius: 4px;
    font-weight: 100;
    width: 20px;
    height: 20px;
}

input[type='checkbox'] {
    accent-color: var(--color-neutral-white);
}

.report-img-container, .report-img-check-container {
    margin-top: 32px;
}

.report-img, .report-img-check {
    width: 64px;
}

.form-head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-header {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xxxl);
}

.form-text {
    margin: 0;
    text-align: center;
    font-weight: var(--font-weight-dark);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-s);
}

.get-report-header {
    margin-bottom: 0;
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xxxl);
}

.get-report-text {
    margin: 0;
    text-align: center;
    font-weight: var(--font-weight-dark);
    font-size: var(--font-size-m);
    line-height: var(--line-height-xl);
}

.user-email {
    margin: 0;
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-s);
}

.report-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.agreement-acceptance {
    margin-top: 40px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 620px;
}

.email-input-form {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 24px;
    height: 64px;
    width: 620px;
    background: var(--color-neutral-white);
    border-radius: 4px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-m);
    line-height: var(--line-height-xl);
}

.valid-input {
    border: 1px solid var(--color-neutral-light);
}

.invalid-input {
    border: 1px solid var(--color-error-red-dark);
}

input.email-input-form:invalid {
    border: 1px solid red;
}

.email-error {
    margin-top: -8px;
    padding-bottom: 16px;
    align-self: start;
    color: var(--color-error-red-dark);
    font-weight: var(--font-weight-dark);
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
}

.share-note {
    background-color: var(--color-custom-292D34);
    color: var(--color-neutral-white);
    width: 100%;
    padding-bottom: 40px;
    padding-top: 40px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.share-header {
    text-align: center;
    margin-top: 0;
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
}

.share-text {
    text-align: center;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
}

.edit-report-btn {
    margin-top: 16px;
    margin-bottom: 24px;
    border: none;
    background: none;
    font-weight: var(--font-weight-dark);
    font-size: var(--font-size-m);
    line-height: var(--line-height-xxxxxl);
}

.edit-report-btn:hover {
    color: var(--color-green-dark);
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .income-report-box {
        width: 388px;
    }

    .form-header {
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-m);
        line-height: var(--line-height-l);
    }

    .form-text {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-xxs);
        line-height: var(--line-height-xxs);
    }

    .user-email {
        font-weight: var(--font-weight-darkest);
        font-size: var(--font-size-xxs);
        line-height: var(--line-height-xxs);
    }

    .share-header {
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-m);
        line-height: var(--line-height-l);
    }

    .share-text {
        text-align: center;
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-xs);
        line-height: var(--line-height-l);
    }

    .email-input-form {
        width: 372px;
    }

    .get-report-btn {
        width: 372px;
    }

    .form-head-container {
        margin-left: 12px;
        margin-right: 12px;
    }

    .get-report-header {
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-m);
        line-height: var(--line-height-l);
    }

    .get-report-text {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
    }

    .agreement-acceptance {
        margin: 32px 0 24px;
        padding-left: 16px;
        padding-right: 16px;
        width: 388px;
    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .income-report-box {
        width: 90%;
    }

    .email-input-form {
        width: 312px;
    }

    .get-report-btn {
        width: 312px;
    }

    .agreement-acceptance {
        width: 312px;
    }
}

/*Smallest screen breakpoint*/
/* On screens that are 360px wide or less*/
@media (max-width: 360px) {
    .email-input-form, .get-report-btn, .agreement-acceptance {
        width: 100%;
    }
}
