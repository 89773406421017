* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Supria-Sans-Regular';
    src: url('/src/assets/fonts/SupriaSans-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Supria-Sans-Bold';
    src: url('/src/assets/fonts/SupriaSans-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Supria-Sans-Black';
    src: url('/src/assets/fonts/SupriaSans-Black.otf') format('opentype');
}

body {
  margin: 0 auto;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-neutral-white);
  color: var(--color-custom-292D34);
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.rubik-font {
    font-family: 'Rubik', sans-serif;
}

.inter-font {
    font-family: 'Inter', sans-serif;
}

.supria-sans-regular-font {
    font-family: 'Supria-Sans-Regular', sans-serif;
}

.supria-sans-bold-font {
    font-family: 'Supria-Sans-Bold', sans-serif;
}

.supria-sans-black-font {
    font-family: 'Supria-Sans-Black', sans-serif;
}
