.footer-img {
    width: 64px;
}

.footer-header {
    color: var(--color-neutral-white);
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxxxl);
}

.footer-text {
    color: var(--color-neutral-white);
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-s);
    line-height: var(--line-height-l);
    width: 620px;
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .footer-img {
        width: 40px;
    }

    .footer-header {
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-m);
        line-height: var(--line-height-l);
    }

    .footer-text {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-xs);
        line-height: var(--line-height-l);
        width: 388px;

    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .footer-text {
        width: 100%;
    }
}
