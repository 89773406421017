.supported-platforms-container {
padding: 32px;
}

.supported-platforms-header {
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xxl);
    font-style: italic;
    text-align: center;
    margin-bottom: 32px;
}

.supported-platforms-carousel {
    margin: 0 auto;
    width: 620px;
}

.logo {
    width: 64px;
}

.alice-carousel__next-btn {
    display: none!important;
}

.alice-carousel__prev-btn {
    display: none!important;
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .supported-platforms-header {
        font-weight: var(--font-weight-darkest);
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
    }

    .supported-platforms-carousel {
        width: 420px;
    }

}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .supported-platforms-carousel {
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
    }

    .supported-platforms-header {
        margin: auto auto 16px;
        width: 320px;
    }

    .logo {
        width: 48px;
    }

}

/*Smallest screen breakpoint*/
/* On screens that are 360px wide or less*/
@media (max-width: 360px) {
    .supported-platforms-header {
        width: 100%;
    }
}
