.loading-container {
    background-color: var(--color-neutral-darkest);
    padding-top: 256px;
    padding-bottom: 256px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-header {
    margin-top: 32px;
    margin-bottom: 8px;
    text-align: center;
    color: var(--color-green-lighter);
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxxxl);
}

.loading-text {
    margin: 0;
    text-align: center;
    color: var(--color-green-lighter);
}
