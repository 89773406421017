.usage-container {
    background-color: var(--color-neutral-lighter);
    text-align: center;
    width: 100%;
    padding: 32px;
}

.usage-header {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxxxl);
    margin: 0;
}

.usage-text {
    margin: auto;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-l);
    line-height: var(--line-height-xl);
    width: 600px;
}

.usage-wrapper {
   margin-top: 32px;

}
.report-usage-examples-container {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 760px;
}

.usage-case-container {
    width: 160px;
}

.usage-img {
    width: 100px;
}
.usage-title {
    font-family: 'Supria-Sans-Regular', sans-serif;
    font-weight: var(--font-weight-darkest);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .usage-header {
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-l);
        line-height: var(--line-height-xxl);
    }

    .usage-text {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
        width: 400px;
    }

    .report-usage-examples-container {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        width: 420px;
    }

    .usage-case-container {
        flex: 0 0 186px;
        margin: 10px;
    }

    .usage-img {
        width: 80px;
    }

    .usage-title {
        font-family: 'Supria-Sans-Regular', sans-serif;
        font-weight: var(--font-weight-darkest);
        font-size: var(--font-size-s);
        line-height: var(--line-height-l);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .report-usage-examples-container {
        display: flex;
        flex-direction: column;
    }

    .usage-case-container {
        display: flex;
        flex-direction: row;
        flex: 0;
        width: 320px;
    }

    .usage-img {
        width: 60px;
    }

    .usage-title {
        margin: auto 0 auto 20px;
        text-align: start;
    }

    .usage-text {
        width: 100%;
    }
}

/*Smallest screen breakpoint*/
/* On screens that are 360px wide or less*/
@media (max-width: 360px) {
    .report-usage-examples-container, .usage-title, .usage-case-container {
        width: 100%;
    }
}
