.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 32px;
    margin-right: 32px;
    padding-bottom: 64px;
}

.not-found-img {
    width: 400px;
}

.not-found-header {
    margin: 0;
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxxl);
}

.not-found-text {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-s);
    line-height: var(--line-height-l);
}

.not-found-link {
    font-weight: var(--font-weight-darkest);
    text-decoration: underline;
    color: inherit;
}

a.not-found-link:hover {
    color: var(--color-green-dark);
}

/*Medium screen breakpoint*/
/* On screens that are 959px wide or less*/
@media (max-width: 959px) {
    .not-found-container {
        margin: 0 auto;
        width: 343px;
    }
    .not-found-img {
        width: 360px;
    }
    .not-found-header {
        margin: 0;
        font-size: var(--font-size-xl);
        line-height: var(--line-height-xxxl);
    }

    .not-found-text {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-xs);
        line-height: var(--line-height-m);
    }

}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
    .not-found-container {
        margin: 0 auto;
        width: 320px;
    }
    .not-found-img {
        width: 300px;
    }

}