html,body{
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.main-content {
  padding-top: 75px;   /* AppBar 27 height + 24 padding-top + 24 padding-bottom  */
}

.footer {
  background-color: var(--color-neutral-darkest);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 42px 24px;
}

/*Mobile screen breakpoint*/
/* On screens that are 419px wide or less*/
@media (max-width: 419px) {
  .main-content {
    padding-top: 56px;   /* AppBar 24 height + 16 padding-top + 16 padding-bottom  */
  }
}

:root {
  /*colors constants*/
  --color-neutral-white: #FFFFFF;
  --color-neutral-black: #000000;
  --color-neutral-lighter: #F7F7F8;
  --color-neutral-light: #CBD2D9;
  --color-neutral-normal: #9AA5B1;
  --color-neutral-dark: #53616F;
  --color-neutral-darkest: #1F2933;
  --color-custom-292D34: #292D34;
  --color-custom-F1F2F6: #F1F2F6;
  --color-custom-57606F: #57606F;
  --color-green-lighter: #E7F9EC;
  --color-green-dark: #459B5F;
  --color-error-red-dark: #F7634F;

  /*fontWeight constants*/
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-dark: 500;
  --font-weight-darker: 600;
  --font-weight-darkest: 700;
  --font-weight-extra-bold: 900;
    /*font size constants*/
  --font-size-xxxs: 12px;
  --font-size-xxs: 14px;
  --font-size-xs: 16px;
  --font-size-s: 18px;
  --font-size-m: 20px;
  --font-size-l: 24px;
  --font-size-xl: 28px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 40px;
  --font-size-xxxxl: 48px;
  /*line height constants*/
  --line-height-xxxxs: 14px;
  --line-height-xxxs: 16px;
  --line-height-xxs: 18px;
  --line-height-xs: 20px;
  --line-height-s: 22px;
  --line-height-m: 24px;
  --line-height-l: 26px;
  --line-height-xl: 30px;
  --line-height-xxl: 36px;
  --line-height-xxxl: 38px;
  --line-height-xxxxl: 48px;
  --line-height-xxxxxl: 60px;

  /*font-family constant*/
  --font-family-primary: 'Rubik';
  --font-family-supria-sans-bold: 'SupriaSans-Bold';
  --font-family-supria-sans-regular: 'SupriaSans-Regular';
  --font-family-supria-sans-medium: 'SupriaSans-Medium';
  --font-family-supria-sans-black: 'SupriaSans-Black';

  /*Screen size constants*/
  --mobile-max: 420px;
  --tablet-max: 959px;
}
